body{
    background-color: #f7f4cd;
}
.carousel-item {
    text-align: center;
}

.carousel-image {
    width: 100%;
    height: 450px;
    max-height: 500px; 
    /* object-fit: cover;  */
}



.image{
    width: 100% !important;
    height: 215px !important;
    max-height: 230px; 
    /* object-fit: cover;  */
}




.carousel-item-mob {
  text-align: center;
}

.carousel-image-mob {
  width: 100%;
  height: 100%;
  max-height: 100px; /* Adjust as needed */
  object-fit: cover; 
}



.image-mob{
  width: 100%;
  height: 100%;
  max-height: 100%; /* Adjust as needed */
  object-fit: cover; /* Ensure images cover the container without distortion */
}

.frequent-categories-container {
    background-color: #f7f4cd; /* Light yellow background */
    padding: 10px;
    border-radius: 5px;
    
  }
  
  .title {
    background-color: #00c8fa;
    color: #2d2859;
    padding: 10px;
    border-radius: 5px;
    width:100%;
    text-align: center;
    font-family: "Anton", sans-serif;
    font-weight: bold;
    font-style: italic;
}

.title-mob{
  
  color: #2d2859;
  padding: 10px;
  border-radius: 5px;
  width:100%;
  text-align: left;
  font-family: "Anton", sans-serif;
  font-weight: bold;
  font-style: italic;
}




  
  .categories-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-top: 20px;
  }
  
  .category-item {
    flex: 0 0 calc(100% / 8); /* 5 items per row */
    margin: 8px 0;
    text-align: center;
  }

  .categories-list-mob {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-top: 5px;
  }
  
  .category-item-mob {
    flex: 0 0 calc(100% / 5); /* 5 items per row */
    margin: 5px 0;
    text-align: center;
  }
  
 
