body {
  margin: 0;
  font-family:"Flama Bold Italic";
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */

}

code {
  font-family: "Flama Bold Italic";
}
