
@media (max-width: 375px){
    
}



#image-text-mob {
    position: absolute;
    color: #2d2859;
    background-color: #f7f4cd;
    padding: 5px;
    text-align: center;
    z-index: 1;
    font-family: "Anton", sans-serif;
    font-weight: bold;
    font-style: italic;
}

#box-start-mob{
    display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
height: 100vh;
width: 100%;
text-align: center;

}

#description-head-mob {
    border: 1px solid #000;
    padding: 5px;
    background-color: #F4F3DE;
    height: 200px;
}

#description-body-mob {
    color: #000;
    text-align: left;
    font-size: 10px;
    font-family: "Anton", sans-serif;
    font-style: italic;
}

#box-mob {
    border: 1px solid #000;
    background-color: #fff;
    height: 120px;
}

#gird-mob {
    border: 1px solid #000;
    padding: 5px;
    background-color: #F4F3DE;
    height: 200px;
}

#upload-button-mob {
    background-color: #2d2859;
    border-radius: 10px;
    font-size: 15px;
    width: auto;
    height: auto;
    font-family: "Anton", sans-serif;
    font-weight: bold;
    font-style: italic;
}

#image-style-mob {
    width: 100px;
    height: 120px;
}

#button-mob {
    background-color: #2d2859;
    border-radius: 10px;
    font-size: 10px;
    width: auto;
    height: auto;
    font-family: "Anton", sans-serif;
    font-weight: bold;
    font-style: italic;
}

/* For web view */

#image-text {
    position: absolute;
    color: #2d2859;
    background-color: #f7f4cd;
    padding: 5px;
    text-align: center;
    z-index: 1;
}

#box-start {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    text-align: center;
}

#box-image{
    width: 100%;
height: 90%;
object-fit: cover;
z-index: 0;
}

#description-head{
    color: #000;
    text-align: left;
    font-size: 25px;
    font-family: "Anton", sans-serif;
    font-weight: bold;
    font-style: italic;
}

#description-body {
    color: #000;
    text-align: left;
    font-size: 18px;
    font-family: "Anton", sans-serif;
    font-style: italic;
}

#gird {
    border: 1px solid #000;
    padding: 5px;
    background-color: #F4F3DE;
    height: 200px;
}

#upload-button {
    background-color: #2d2859;
    border-radius: 10px;
    font-size: 20px;
    width: auto;
    height: 50px;
    font-family: "Anton", sans-serif;
    font-weight: bold;
    font-style: italic;
}

#button-save {
    background-color: #2d2859;
    border-radius: 15px;
    font-size: 18px;
    width: 150px !important;
    height:auto !important;
    font-family: "Anton", sans-serif;
    font-weight: bold;
    font-style: italic;
}




